<template>
<div class="classes">
  <h1 class="title grey--text">场地收入</h1>
  <v-container class="my-5">
    <v-layout justify-center class="pa3">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">金额</th>
              <th class="text-left">性质</th>
              <th class="text-left">活动</th>
              <th class="text-left">时间</th>
              <th class="text-left">余额</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in venueMoneyRecords" :key="item.host_money_record_id">
              <td class="caption">{{ item.amount_yuan }}</td>
              <td class="caption">{{ item.category }}</td>
              <td class="caption">{{ item.lesson }}</td>
              <td class="caption">{{ item.lesson_time }}</td>
              <td class="caption">{{ item.balance_yuan }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-layout>
  </v-container>
</div>
</template>

<script>
import ProgramService from '@/services/ProgramService'
import moment from 'moment'

export default {
  components: {
  },
  data () {
    return {
      classId: null,
      venueId: null,
      venueMoneyRecords: []
    }
  },
  async mounted () {
    this.hostId = parseInt(this.$store.state.route.params.hostId)
    console.log(`hostId: ${this.hostId}`)
    this.venueMoneyRecords = await this.getHostMoneyRecordsById(this.hostId)
    this.venueMoneyRecords.forEach(venueMoneyRecord => {
      venueMoneyRecord.lesson_time = moment(venueMoneyRecord.lesson_time).format('YYYY-MM-DD(dddd) HH:mm')
      venueMoneyRecord.amount_yuan = venueMoneyRecord.amount_fen / 100
      venueMoneyRecord.balance_yuan = venueMoneyRecord.balance_fen / 100
      venueMoneyRecord.payment_yuan = venueMoneyRecord.payment_fen / 100
    })
  },
  methods: {
    async getHostMoneyRecordsById (hostId, limit, offset) {
      console.log('\n#getHostMoneyRecordsById starts ... ')
      console.log('hostId: ', hostId, 'limit: ', limit, 'offset: ', offset)
      const res = await ProgramService.getHostMoneyRecordsById({ hostId, limit, offset })
      console.log('retrieve hostMoneyRecords successful! \nhostMoneyRecords: ', res.data)
      return res.data
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
